<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_01">
          <span>
            <label>기간</label>
            <date-picker
              v-model="search.startDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="search.endDate.value"
              :popover="{ visibility: 'click' }"
              :max-date="new Date()"
              :min-date="search.startDate.value"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label>검색어</label>
            <span>
              <base-select :options="searchTypeOptions" v-model="search.type" />
              <input
                type="text"
                v-model="search.q"
                placeholder="검색어를 입력해 주세요."
              />
              <base-button type="primary" nativeType="submit">검색</base-button>
            </span>
          </span>
        </div>
      </form>
    </section>
    <section class="cont_02">
      <div class="total_box addnumbox">
        <div>
          <div class="srch_total pl_40">
            <span class="srch_total_title">검색 :</span>
            <span class="total_count">0</span>
            <span>명</span>
          </div>
          <div class="total ml_31">
            <span class="total_title">전체 :</span>
            <span class="total_count">{{ $n(totalItems) }}</span>
            <span>건</span>
          </div>
        </div>
        <span class="pr_40">
          <base-select
            :options="search.size.option"
            v-model="search.size.value"
            @input="fetch(1)"
            placeholder="선택"
          />
        </span>
      </div>
      <div class="table_area">
        <div>
          <base-table
            :useNo="true"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :loading="contentLoading"
            :fields="fields"
            :items="items"
            :isEdit="false"
            @sort="onSortClick"
          />
        </div>
        <base-pagination
          :total="totalItems"
          :loading="contentLoading"
          :currentPage="currentPage"
          :pageCount="totalPages"
          :perPage="pageSize"
          @change="fetch"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import client from "api-client";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
// import Dialog from "@/components/Dialog.vue";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "SystemAccountLog",
  components: {
    BaseTable,
    BaseButton,
    BasePagination,
    BaseSelect,
  },
  data() {
    return {
      currentSort: "",
      fields: [
        {
          key: "cover",
          width: "100px",
          label: "관리자 아이디",
          tag: "img",
          useSort: true,
        },
        {
          key: "title",
          width: "120px",
          label: "메뉴",
        },
        {
          key: "title",
          width: "180px",
          label: "제목",
          useSort: true,
        },
        {
          key: "hitCount",
          width: "100px",
          label: "작동",
        },
        {
          key: "registerDate",
          width: "120px",
          label: "처리일시",
          formatter: {
            name: "date",
            type: "long",
          },
          useSort: true,
        },
        {
          key: "key",
          width: "120px",
          label: "ip",
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      selectedDivision: "",
      searchTypeOptions: [
        { name: "제목", value: "title" },
        { name: "메뉴", value: "menu" },
      ],
      search: {
        type: "",
        q: "",
        startDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        endDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        size: {
          event: true,
          option: [
            {
              name: "10개씩",
              value: 10,
            },
            {
              name: "20개씩",
              value: 20,
            },
            {
              name: "30개씩",
              value: 30,
            },
            {
              name: "40개씩",
              value: 40,
            },
            {
              name: "50개씩",
              value: 50,
            },
          ],
          value: 10,
        },
      },
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {
    download(url, name, type, params) {
      params = {
        page: this.currentPage,
        size: this.pageSize,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/banner`;
      File.download(url, name, type, params);
    },
    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },

    onSortClick(value) {
      if (value.item.key != this.currentSort) {
        this.clickedNum = 0;
        for (let i = 0; i < this.fields.length; i++) {
          this.fields[i].clickedNum = 0;
        }
      }
      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      this.fetch(1);
    },
    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };

      if (this.currentSort) {
        params.sort = `${this.currentSort}.asc`;
      }

      if (this.search.startDate.value && this.search.endDate.value) {
        params.startDate = this.$date(
          new Date(this.search.startDate.value)
        ).format("YYYY-MM-DD");
        params.endDate = this.$date(new Date(this.search.endDate.value)).format(
          "YYYY-MM-DD"
        );
      }

      Object.entries(this.search).forEach((row) => {
        if (row[1].event) {
          if (row[1].value.length !== 0) {
            params[row[0]] = row[1].value;
            this.pageSize = Number(row[1].value);
          }
        }
      });

      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      } else if (0 < this.search.q.length) {
        params.important = this.search.q;
        /*
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
        */
      }

      // await client.bannerFindAll(params).then(
      //   (response) => {
      //     const { data } = response;
      //     console.log(data);
      //     data.items.forEach((item) => {
      //       // 이미지
      //       if (item.cover.name) {
      //         item.cover = `${item.cover.url}${item.cover.path}?size=100`;
      //       }
      //     });
      //     this.items = data.items;
      //     this.totalItems = data.totalItems;
      //     this.totalPages = data.totalPages;
      //     this.contentLoading = false;
      //   },
      //   () => {
      //     // console.log(message);
      //     this.items = [];
      //     this.totalItems = 0;
      //     this.totalPages = 0;
      //     this.contentLoading = false;
      //   }
      // );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
